/**
 * remove_from_cart event
 *
 * Cannot be attached directly because the mini cart doesn't necessarily contain the remove button on page load.
 */
jQuery(document).on("click", ".remove_from_cart_button, .remove", (event) => {

	// console.log("remove_from_cart event" + new Date().getTime())

	try {

		let url       = new URL(jQuery(event.currentTarget).attr("href"))
		let productId = wpm.getProductIdByCartItemKeyUrl(url)

		wpm.removeProductFromCart(productId)

	} catch (e) {
		console.error(e)
	}
})


/**
 * begin_checkout event
 *
 * Cannot be attached directly because the mini cart doesn't necessarily contain the remove button on page load.
 */
let checkoutButtonClasses = [
	// ".checkout",
	".checkout-button",
	".cart-checkout-button",
	".button.checkout",
	".xoo-wsc-ft-btn-checkout", // https://xootix.com/side-cart-for-woocommerce/
	".elementor-button--checkout",
].join(",")

// https://wordpress.stackexchange.com/a/352171/68337
jQuery(document).on("click init_checkout", checkoutButtonClasses, () => {

	// console.log("init_checkout at: " + new Date().getTime())

	jQuery(document).trigger("wpmBeginCheckout")
})

jQuery(document).on("updated_cart_totals", () => {
	jQuery(document).trigger("wpmViewCart")
})

/**
 * Set up PWM events
 */

// track checkout option event: purchase click
// https://wordpress.stackexchange.com/a/352171/68337
jQuery(document).on("wpmLoad", (event) => {
	jQuery(document).on("payment_method_selected", () => {

		if (false === wpm.paymentMethodSelected) {
			wpm.fireCheckoutProgress(3)
		}

		wpm.fireCheckoutOption(3, jQuery("input[name='payment_method']:checked").val())
		wpm.paymentMethodSelected = true
	})
})

// populate the wpmDataLayer with the cart items
jQuery(document).on("wpmLoad", () => {

	try {
		// When a new session is initiated there are no items in the cart,
		// so we can save the call to get the cart items
		if (wpm.doesWooCommerceCartExist()) wpm.getCartItems()

	} catch (e) {
		console.error(e)
	}
})

// get all add-to-cart= products from backend
jQuery(document).on("wpmLoad", () => {

	wpmDataLayer.products = wpmDataLayer.products || {}

	// scan page for add-to-cart= links
	let productIds = wpm.getAddToCartLinkProductIds()

	wpm.getProductsFromBackend(productIds)
})

/**
 * Save the referrer into a cookie
 */

jQuery(document).on("wpmLoad", () => {

	// can't use session storage as we can't read it from the server
	if (!wpm.getCookie("wpmReferrer")) {

		if (document.referrer) {
			let referrerUrl      = new URL(document.referrer)
			let referrerHostname = referrerUrl.hostname

			if (referrerHostname !== window.location.host) {
				wpm.setCookie("wpmReferrer", referrerHostname)
			}
		}
	}
})


/**
 * Create our own load event in order to better handle script flow execution when JS "optimizers" shuffle the code.
 */
jQuery(document).on("wpmLoad", () => {
	// document.addEventListener("wpmLoad", function () {
	try {
		if (typeof wpmDataLayer != "undefined" && !wpmDataLayer?.wpmLoadFired) {

			jQuery(document).trigger("wpmLoadAlways")

			if (wpmDataLayer?.shop) {
				if (
					"product" === wpmDataLayer.shop.page_type &&
					"variable" !== wpmDataLayer.shop.product_type &&
					wpm.getMainProductIdFromProductPage()
				) {
					let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage())
					jQuery(document).trigger("wpmViewItem", product)
				} else if ("product_category" === wpmDataLayer.shop.page_type) {
					jQuery(document).trigger("wpmCategory")
				} else if ("search" === wpmDataLayer.shop.page_type) {
					jQuery(document).trigger("wpmSearch")
				} else if ("cart" === wpmDataLayer.shop.page_type) {
					jQuery(document).trigger("wpmViewCart")
				} else if ("order_received_page" === wpmDataLayer.shop.page_type && wpmDataLayer.order) {
					if (!wpm.isOrderIdStored(wpmDataLayer.order.id)) {
						jQuery(document).trigger("wpmOrderReceivedPage")
						wpm.writeOrderIdToStorage(wpmDataLayer.order.id)
						if (typeof wpm.acrRemoveCookie === "function") wpm.acrRemoveCookie()
					}
				} else {
					jQuery(document).trigger("wpmEverywhereElse")
				}
			} else {
				jQuery(document).trigger("wpmEverywhereElse")
			}

			if (wpmDataLayer?.user?.id && !wpm.hasLoginEventFired()) {
				jQuery(document).trigger("wpmLogin")
				wpm.setLoginEventFired()
			}

			// /**
			//  * Load mini cart fragments into a wpm session storage key,
			//  * after the document load event.
			//  */
			// jQuery(document).ajaxSend(function (event, jqxhr, settings) {
			// 	// console.log('settings.url: ' + settings.url);
			//
			// 	if (settings.url.includes("get_refreshed_fragments") && sessionStorage) {
			// 		if (!sessionStorage.getItem("wpmMiniCartActive")) {
			// 			sessionStorage.setItem("wpmMiniCartActive", JSON.stringify(true))
			// 		}
			// 	}
			// })

			wpmDataLayer.wpmLoadFired = true
		}

	} catch (e) {
		console.error(e)
	}
})

jQuery(document).on("wpmLoad", async () => {

	if (
		window.sessionStorage &&
		window.sessionStorage.getItem("_pmw_endpoint_available") &&
		!JSON.parse(window.sessionStorage.getItem("_pmw_endpoint_available"))
	) {
		console.error("Pixel Manager for WooCommerce: REST endpoint is not available. Using admin-ajax.php instead.")
	}
})


/**
 * Load all pixels
 */
jQuery(document).on("wpmPreLoadPixels", () => {

	if (wpmDataLayer?.shop?.cookie_consent_mgmt?.explicit_consent && !wpm.explicitConsentStateAlreadySet()) {
		wpm.updateConsentCookieValues(null, null, true)
	}

	jQuery(document).trigger("wpmLoadPixels", {})
})


/**
 * All ecommerce events
 */

jQuery(document).on("wpmAddToCart", (event, product) => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event  : "addToCart",
		product: product,
	}

	// Facebook
	// If Facebook pixel is loaded, add Facebook server to server event data to the payload
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "AddToCart",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : wpm.fbGetProductDataForCapiEvent(product),
		}
	}

	// TikTok
	// https://ads.tiktok.com/gateway/docs/index?identify_key=c0138ffadd90a955c1f0670a56fe348d1d40680b3c89461e09f78ed26785164b&language=ENGLISH&doc_id=1739585702922241#item-link-Adding%20parameters%20to%20event%20code
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "AddToCart",
			event_id  : wpm.getRandomEventId(),
			context   : wpm.getTikTokUserDataFromBrowser(),
			properties: {
				value   : product.price * product.quantity,
				currency: product.currency,
				contents: [{
					content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
					content_type: "product",
					content_name: product.name,
					quantity    : product.quantity,
					price       : product.price,
				}],
			},
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientSideAddToCart", payload)

	/**
	 * Process the server-to-server event
	 */

	// If function wpm.isServerToServerEnabled() exists, then run it
	if (typeof wpm.sendEventPayloadToServer === "function") {
		wpm.sendEventPayloadToServer(payload)
	}
})

jQuery(document).on("wpmBeginCheckout", () => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "beginCheckout",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "InitiateCheckout",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {},
		}

		if (wpmDataLayer?.cart && !jQuery.isEmptyObject(wpmDataLayer.cart)) {
			payload.facebook.custom_data = {
				content_type: "product",
				content_ids : wpm.fbGetContentIdsFromCart(),
				value       : wpm.getCartValue(),
				currency    : wpmDataLayer.shop.currency,
			}
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event   : "InitiateCheckout",
			event_id: wpm.getRandomEventId(),
			context : wpm.getTikTokUserDataFromBrowser(),
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientSideBeginCheckout", payload)

	/**
	 * Process the server-to-server event
	 */

	// If function wpm.isServerToServerEnabled() exists, then run it
	if (typeof wpm.sendEventPayloadToServer === "function") {
		wpm.sendEventPayloadToServer(payload)
	}
})

jQuery(document).on("wpmAddToWishlist", (event, product) => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event  : "addToWishlist",
		product: product,
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "AddToWishlist",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : wpm.fbGetProductDataForCapiEvent(product),
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "AddToWishlist",
			event_id  : wpm.getRandomEventId(),
			context   : wpm.getTikTokUserDataFromBrowser(),
			properties: {
				value   : product.price * product.quantity,
				currency: product.currency,
				contents: [{
					content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
					content_type: "product",
					content_name: product.name,
					quantity    : product.quantity,
					price       : product.price,
				}],
			},
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientSideAddToWishlist", payload)

	/**
	 * Process the server-to-server event
	 */

	// If function wpm.isServerToServerEnabled() exists, then run it
	if (typeof wpm.sendEventPayloadToServer === "function") {
		wpm.sendEventPayloadToServer(payload)
	}
})

jQuery(document).on("wpmViewItem", (event, product = null) => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event  : "viewItem",
		product: product,
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "ViewContent",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {},
		}

		if (product) {
			payload.facebook.custom_data = wpm.fbGetProductDataForCapiEvent(product)
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event   : "ViewContent",
			event_id: wpm.getRandomEventId(),
			context : wpm.getTikTokUserDataFromBrowser(),
		}

		if (product) {
			payload.tiktok.properties = {
				value   : product.price * product.quantity,
				currency: product.currency,
				contents: [{
					content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
					content_type: "product",
					content_name: product.name,
					quantity    : product.quantity,
					price       : product.price,
				}],
			}
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientSideViewItem", payload)

	/**
	 * Process the server-to-server event
	 */

	// If function wpm.isServerToServerEnabled() exists, then run it
	if (typeof wpm.sendEventPayloadToServer === "function") {
		wpm.sendEventPayloadToServer(payload)
	}
})

jQuery(document).on("wpmSearch", () => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "search",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "Search",
			event_id        : wpm.getFbRandomEventId(),
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {
				search_string: wpm.getSearchTermFromUrl(),
			},
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "Search",
			event_id  : wpm.getRandomEventId(),
			context   : wpm.getTikTokUserDataFromBrowser(),
			properties: {
				query: wpm.getSearchTermFromUrl(),
			},
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientSideSearch", payload)

	/**
	 * Process the server-to-server event
	 */

	// If function wpm.isServerToServerEnabled() exists, then run it
	if (typeof wpm.sendEventPayloadToServer === "function") {
		wpm.sendEventPayloadToServer(payload)
	}
})

jQuery(document).on("wpmPlaceOrder", () => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "placeOrder",
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event   : "PlaceAnOrder",
			event_id: wpm.getRandomEventId(),
			context : wpm.getTikTokUserDataFromBrowser(),
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientPlaceOrder", payload)

	/**
	 * Process the server-to-server event
	 */

	// If function wpm.isServerToServerEnabled() exists, then run it
	if (typeof wpm.sendEventPayloadToServer === "function") {
		wpm.sendEventPayloadToServer(payload)
	}
})

jQuery(document).on("wpmOrderReceivedPage", () => {

	/**
	 * Prepare the payload
	 */

	let payload = {
		event: "orderReceived",
	}

	// Facebook
	if (wpmDataLayer?.pixels?.facebook?.loaded) {
		payload.facebook = {
			event_name      : "Purchase",
			event_id        : wpmDataLayer.order.id,
			user_data       : wpm.getFbUserData(),
			event_source_url: window.location.href,
			custom_data     : {
				content_type: "product",
				value       : wpmDataLayer.order.value_filtered,
				currency    : wpmDataLayer.order.currency,
				content_ids : wpm.facebookContentIds(),
			},
		}
	}

	// TikTok
	if (wpmDataLayer?.pixels?.tiktok?.loaded) {
		payload.tiktok = {
			event     : "CompletePayment",
			event_id  : wpm.getRandomEventId(),
			context   : wpm.getTikTokUserDataFromBrowser(),
			properties: {
				value   : wpmDataLayer.order.value_filtered,
				currency: wpmDataLayer.order.currency,
				contents: wpm.getTikTokOrderItemIds(),
			},
		}
	}

	/**
	 * Process the client-to-server event
	 */

	jQuery(document).trigger("wpmClientSideOrderReceivedPage", payload)

	/**
	 * Process the server-to-server event
	 */

	// ! No server-to-server event is sent for this event because it is compiled and sent from the server directly
})





